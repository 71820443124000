<template>
    <div>
      <div class="addtobasket" @click="add">
        Add to Basket
      </div>


      <div class="question" v-if="continueShopping">
        <p>The {{ productName }} has been added to your basket.</p>
        <p>Continue Shopping?</p>
        <button @click="hideBox">Yes</button><button @click="goToBasket">Go to shopping cart</button>
      </div>

    </div>
    
</template>

<script>
export default {
    props:{
      productName: String,
      cost: Number
    },
    
    methods:{
      add:function(){

      this.$store.commit(
        'addToBasket',
        {
          name: this.productName,
          price: this.cost,
        }
      );

      this.continueShopping = true;

      /*
      if( confirm( "Go to basket" ) ){



      }
    */

    },
    goToBasket: function(){
        let basket = document.getElementsByClassName('basket');

        if( basket.length === 0 ){
          document.getElementsByClassName('mybasket')[0].click()
        }

        this.continueShopping = false;
        
        window.scrollTo({top: 0, behavior: 'smooth'})
    },
    hideBox: function(){
      this.continueShopping = false;
    }
  },
  data:function(){
    return{
      continueShopping: false
    }
  }
}
</script>

<style scoped>
  .addtobasket {
    position: relative;
    background-color: #ca6b35;
    padding: 1.5rem 3rem;
    margin: 1rem;
    color: white;
    font-size: 2rem;
    font-weight: 700;
    cursor: pointer;
  }

  
.question{
  position: fixed;
  top: 3rem;
  right: 50%;
  width: 350px;
  background: white;
  border: 2px solid black;
  border-radius: 0.25rem;
  z-index: 103;
  padding: 15px;
  margin-right: -190px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.question button{
  border: none;
  padding: 1rem;
  margin: 1rem;
  background-color: #ca6b35;
  font-size: 1.2rem;
  color: white;
}

  @media screen and (max-width: 550px){
    .addtobasket {
      font-size: 1.2rem;
      padding: 1rem 2rem;
      
    }
  }
</style>
<template>
    <div>
      <div class="cloudbanner">
          <div>
              <h1>About Us</h1>
          </div>
      </div>

    <div class="divider"></div>

      <div class="garden2">
        
            <div class="whitebox">
             <p>Keith Watkins, MD of Litefirst, has had a lifelong fascination for light with the earliest memories ranging from installing lights in his sisters dolls house when he was about 10 years old to designing and installing showcase lighting in his own kitchen when he bought his first house. <br> <br>

            One memory that has stuck with him since he was 16 was visiting some friends who were keen to show off their garden, lit by a new 2000 Watt floodlight. It looked amazing when viewed from the back door. However, when he went to the bottom of the garden and turned around, he was blinded so much by the light the whole garden appeared black. This inspired him to create his own lighting system where it looks beautiful from any angle without dazzle and glare.</p>
            </div>
            <img src="/images/lanterncircle.png" id="lanterncircle">

      </div>

      <div class="whiteboxmobile">
             <p>Keith Watkins, MD of Litefirst, has had a lifelong fascination for light with the earliest memories ranging from installing lights in his sisters dolls house when he was about 10 years old to designing and installing showcase lighting in his own kitchen when he bought his first house. <br> <br>

            One memory that has stuck with him since he was 16 was visiting some friends who were keen to show off their garden, lit by a new 2000 Watt floodlight. It looked amazing when viewed from the back door. However, when he went to the bottom of the garden and turned around, he was blinded so much by the light the whole garden appeared black. This inspired him to create his own lighting system where it looks beautiful from any angle without dazzle and glare.</p>
            </div>

      <div class="whitebar">
        <h1>To simply light your garden</h1>
      </div>
      <div class="exta-description">
        When the time came to install lights in his own garden a couple of years ago, he was very disappointed by the quality of light produced by the available range of products and to a certain extent things hadn’t changed that much since the experience with the floodlight all those years ago, so armed with 35 years of experience in the electronics industry in both manufacturing and design and 14 years  designing  for the LED Lighting Industry, he decided to develop his own garden lighting system and Litefirst was born
      </div>
    </div>
</template>

<script>

export default {
  components: {
    
  }
}
</script>

<style scoped>
.cloudbanner {
  background-image: url("/images/cloudthin.jpg");
  position: relative;
  height: 250px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloudbanner h1 {
  position: relative;
  color: white;
  font-size: 100px;
  font-family: turbinado-pro;
  font-weight: 400;
  text-shadow: 2px 2px 5px #595a5c;
}

.garden2 {
  background-image: url("/images/garden2.jpg");
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 970px;
  width: 100%;
  margin: auto;
  
}


#garden2image {
  position: relative;
}

.whitebox {
  position: relative;
  background-color: white;
  width: 581px;
  height: 605px;
  margin-top: 23rem;
}

.whiteboxmobile {
  display: none;
}

.whitebox p {
  padding: 1rem;
  font-size: 25px;
  font-family: din-2014;
  font-weight: 500;
  text-align: left;
  width: auto;
}

#lanterncircle {
  position: relative;
  width: 446px;
  margin-top: 32.5rem;

  
}

.whitebar {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whitebar h1 {
  position: relative;
  font-family: turbinado-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 110px;
  color: #c96a36
  }

.exta-description{
    padding: 1rem 3rem;
    font-size: 25px;
    font-family: din-2014;
    font-weight: 500;
    text-align: left;
    width: auto;
    text-align: center;
}

@media screen and (max-width: 1024px){

.whitebox {
  position: relative;
  background-color: white;
  width: 550px;
  height: 605px;
  margin-top: 23rem;
}



}

@media screen and (max-width: 1000px){

.garden2 {
  background-image: url("/images/garden2.jpg");
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 550px;
  margin: auto;
  
}

.whitebox {
  display: none;
}

.whiteboxmobile {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: row;
  background-color: white;
  width: auto;


}

.whiteboxmobile p {
  padding: 1rem;
  font-size: 18pt;
  font-family: din-2014;
  font-weight: 500;
  text-align: left;
}

#lanterncircle {
  position: relative;
  width: 426px;
  margin-bottom: 25rem;

  }

.whitebar h1 {
  position: relative;
  font-family: turbinado-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 100px;
  color: #c96a36
  }
}

@media screen and (max-width: 450px){

#lanterncircle {
  position: relative;
  margin-bottom: 23rem;
  }

.whiteboxmobile {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: row;
  background-color: white;
}

.whiteboxmobile p {
  padding: 1rem;
  font-size: 18pt;
  font-family: din-2014;
  font-weight: 500;
  text-align: left;
}

.whitebar {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whitebar h1 {
  position: relative;
  font-family: turbinado-pro, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 90px;
  color: #c96a36
  }

  .exta-description{
    padding: 1rem;
  }
}
</style>
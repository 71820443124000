import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(VueAxios, axios)

const store = new Vuex.Store({
  state: {
    basket: [ //create an array where the products will be stored.
      /**
       * The Basket is currenty empry but objects can be added to the arrange
       * 
       * We need to use getters to get the data and mutations to change to data.
       * This is must be done like this as it allows the applicataion to track the
       * changes in the values (In this case the products in the basket)
       */
    ],
    products: {
      mainProducts: [ 
        { name: "Single Light Starter Pack with WiFi", value:41.99 },
        { name: "Single Light Starter Pack without WiFi", value:35.99 },
        { name: "2 Light Starter Pack with WiFi", value:71.99 },
        { name: "2 Light Starter Pack without WiFi", value:65.49 },
        { name: "3 Light Starter Pack with WiFi", value:99.99 },
        { name: "3 Light Starter Pack without WiFi", value:92.99 }
      ],
      extraLights: [
        { name: "Extra Light", value:34.99 },
        { name: "2 Extra Lights", value:64.99 },
        { name: "3 Extra Lights", value:91.49 },
        { name: "4 Extra Lights", value:115.99 },
      ],
      extraProducts: [
        { name: "2 Metre Extension Cable", value:4.79 },
        { name: "3 Metre Extension Cable", value:5.39 },
        { name: "4 Metre Extension Cable", value:5.99 },
        { name: "T-Connector", value:4.99 }
      ]
    },
    count: 0, //keep a account so an id can be added to each product.
    sessionId: 0 //Keep the session id for the server
  },
  getters: { //getters allow the application to access your state data (the basket)
    
    /**
     * gets the basket as an array of objects
     * 
     * @param Object state 
     * @returns Array (Basket)
     */
    getBasket: state => {
      return state.basket
    },

    /**
     * Returns the current number to be allocated as an ID to the basket.
     * 
     * @param Object state 
     * @returns Number 
     */
    getCount: state => {
      return state.count;
    },

    getSession: state => {
      return state.sessionId
    },

    getProducts: state =>{
      return state.products
    }
  },
  mutations: { //mutations allow you to change the state data

    /**
     * Takes in a project name and price and adds
     * it to the basket
     * 
     * @param Object state 
     * @param String name 
     * @param Number price 
     */
    addToBasket (state, payload) {
      let id=state.count;
      let price = payload.price
      let name = payload.name
      

      state.basket.push({
        id: id,
        name: name,
        price: price
      })

      state.count++;

      //remove any old delivery charges
      let basket = [];
      state.basket.forEach(p => {
        if( p.name != 'Packing and delivery' ){
          basket.push(p);
        }
      })
      

      //calculate delivery
      let deliveryCost = 0;

      basket.forEach(p => {

        //check if product is in extraProducts
        state.products.extraProducts.forEach(o => {
          if( o.name == p.name ){
            deliveryCost = (1.99 > deliveryCost) ? 1.99 : deliveryCost;
          }
        });

        //check if product is in extraLights
        state.products.extraLights.forEach(o => {
          if( o.name == p.name ){
            deliveryCost = (3.99 > deliveryCost) ? 3.99 : deliveryCost;
          }
        });

        //check if product is in mainProducts
        state.products.mainProducts.forEach(o => {
          if( o.name == p.name ){
            deliveryCost = (9.99 > deliveryCost) ? 9.99 : deliveryCost;
          }
        });

      });

      basket.push({
        id: 0,
        name: 'Packing and delivery',
        price: deliveryCost
      })

      state.basket = basket;

      //export cart to server
      let data = {order: JSON.stringify( state )};

      axios.post( 'https://admin.litefirst.co.uk/api/update', data ).then(function(response) {
        state.sessionId = response.data
      })
      
    },

    /**
     * Takes in product ID and finds and removes it from the basket
     * 
     * This works by creating a new array to the objects are copied to
     * with the exacption of the object that has an id that matches the
     * id use in the paramater
     * 
     * @param Object state 
     * @param Number id 
     */
    removeFromBasket( state, id ) {

      let newBasket = [] //new basket

      state.basket.forEach( product => { //for each product in the basket
        if( product.id != id ) //if the object.id does not match 
        {
          newBasket.push( product ) //add to basket
        }
      } )

      state.basket = newBasket //assign newBasket to the Vuex State.

      //remove any old delivery charges
      let basket = [];
      state.basket.forEach(p => {
        if( p.name != 'Packing and delivery' ){
          basket.push(p);
        }
      })
      

      //calculate delivery
      let deliveryCost = 0;

      basket.forEach(p => {

        //check if product is in extraProducts
        state.products.extraProducts.forEach(o => {
          if( o.name == p.name ){
            deliveryCost = (1.99 > deliveryCost) ? 1.99 : deliveryCost;
          }
        });

        //check if product is in extraLights
        state.products.extraLights.forEach(o => {
          if( o.name == p.name ){
            deliveryCost = (3.99 > deliveryCost) ? 3.99 : deliveryCost;
          }
        });

        //check if product is in mainProducts
        state.products.mainProducts.forEach(o => {
          if( o.name == p.name ){
            deliveryCost = (9.99 > deliveryCost) ? 9.99 : deliveryCost;
          }
        });

      });

      basket.push({
        id: 0,
        name: 'Packing and delivery',
        price: deliveryCost
      })

      state.basket = basket;

      //export cart to server
      let data = {order: JSON.stringify( state )};

      axios.post( 'https://admin.litefirst.co.uk/api/update', data ).then(function(response) {
        state.sessionId = response.data
      })

    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
<div>
  <div class="footer">
      <div>+44 (0)7378 827111 <br> <a href="mailto:keith.watkins@litefirst.co.uk">keith.watkins@litefirst.co.uk</a></div>
      <div>Company No 10258263 <br>
        VAT No 244748583</div>
      <div>Starboard PCB Design Ltd T/A Litefirst, Attention : Powell & Associates, 28-29 New Road, Kidderminster, Worcestershire. DY10 1AF United Kingdom</div>
      <div class="fb"><a href="https://www.facebook.com/plugandplaygardenlights" target="_blank"><img src="/images/FindUs-FB-RGB-BRC-Site-500.svg"></a></div>
  </div>
  

</div>
</template>

<script>


export default {
  
  
}
</script>

<style scoped>
.footer {
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.footer > div {
  max-width: 50%;
  margin: 2rem;
  text-align: center;
  line-height: 40px;
  font-size: 1.3rem;
}

.fb{
  display: none;
}

@media screen and (max-width: 900px){
  .footer > div {
    width: auto;
    margin: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
  }

  .footer > .fb{
    display: block;
    width: 75vw;
  }

  .footer {
    flex-direction: column
  }

}

@media screen and (max-width: 530px){
    .footer > div {
  width: auto;
  margin: 20px;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
}

.footer {
  flex-direction: column
}
}



 @import url("https://use.typekit.net/fja2htz.css");
</style>

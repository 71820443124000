<template>
<div>
    <div class="cloudbanner">
      <div>
          <h1>Pre-order</h1>
      </div>
    </div>

    <div class="divider"></div>


    <div class="wrapper">
      <div class="title">
        <h3>Starter Packs</h3>
      </div>

      <div class="price1">
        <div class="row" v-for="product in getProducts.mainProducts" :key="product.index">
          <div class="product">{{ product.name }}</div>
          <div class="price">&pound;{{ product.value }}</div>
          <AddToBasket :productName="product.name" :cost="product.value"/> 
        </div>
      </div>

      <div class="title">
        <h3>Expansion Packs</h3>
      </div>

      <div class="price1">
        <div class="row" v-for="product in getProducts.extraLights" :key="product.index">
          <div class="product">{{ product.name }}</div>
          <div class="price">&pound;{{ product.value }}</div>
          <AddToBasket :productName="product.name" :cost="product.value"/> 
        </div>
      </div>

      <div class="title">
        <h3>Extras</h3>
      </div>

      <div class="price1">
        <div class="row" v-for="product in getProducts.extraProducts" :key="product.index">
          <div class="product">{{ product.name }}</div>
          <div class="price">&pound;{{ product.value }}</div>
          <AddToBasket :productName="product.name" :cost="product.value"/> 
        </div>
      </div>
    </div>
  </div>
    
</template>

<script>
import AddToBasket from '../components/AddToBasket.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PreOrder',
  components: {
    AddToBasket
  },
  computed:{ //computed allows the values to constanty watch and the app to dynamically update values
    ...mapGetters({ //map getters helper method - see https://vuex.vuejs.org/guide/getters.html#the-mapgetters-helper
      getProducts: 'getProducts' //allows quick access to the basket. This can be used in the tempalte code. 
    }),
  }  
}
</script>

<style scoped>

  @import url("https://use.typekit.net/fja2htz.css");
  @import url("https://use.typekit.net/evq5kgu.css");
  @import url("https://use.typekit.net/wuh1hlk.css");
  @import url("https://use.typekit.net/qoa5jej.css");

.cloudbanner {
  background-image: url("/images/cloudthin.jpg");
  background-size: cover;
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cloudbanner h1 {
  position: relative;
  color: white;
  font-size: 100px;
  font-family: turbinado-pro;
  font-weight: 400;
  text-shadow: 2px 2px 5px #595a5c;
  margin: 4rem 0;
}

.wrapper {
  background-image: url("/images/cloud2.jpg");
  background-size: cover;
  position: relative;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.title {
  position: relative;
  display: flex;
  background-color: white;
  width: 75%;
  margin-top: 9rem;
  justify-content: right;
}

.title h3 {
  position: relative;
  margin: 1rem;
  font-family: bebas-neue-rounded, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 4rem;
  color: #c96a36;
}

.price1 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-top: 2rem;
  justify-content: right;
  align-items: right;
  text-align: center;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
  background-color: white;
  margin-bottom: 1rem;
}

.product {
  width: 415px;
  font-family: din-2014;
  font-size: 2rem;
}

.price {
  width: 150px;
  font-family: din-2014;
  font-size: 2.5rem;
  font-weight: 600;
}

.product, .price{
  max-width: 75vw;
}

@media screen and (max-width: 1200px){
  .title, .price1{
    width: 100%;
  }
}

@media screen and (max-width: 900px){
  .product, .price{
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 767px){
  .row{
    flex-direction: column;
    padding: 1rem;
  }

  .product{
    font-size: 2rem;
  }

  .title{
    justify-content: center;
  }
}

</style>